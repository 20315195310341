import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Snackbar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import frontImage from "../../assets/contact/frontImage.png";
import contactImage from "../../assets/contact/contactImage.png";
import Vectorline from "../../assets/contact/Vectorline.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import PinDropSharpIcon from "@mui/icons-material/PinDropSharp";
import WifiCallingIcon from "@mui/icons-material/WifiCalling";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import CardSectionMobile from "../../components/cardSection/CardSectionMobile";

const ContactPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen1 = useMediaQuery(theme.breakpoints.down("md"));

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [text, setText] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = () => {
    if (name && email && mobile && text) {
      setSnackbarOpen(true);
      setName("");
      setEmail("");
      setMobile("");
      setText("");
    } else {
      setSnackbarOpen(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      component="main"
      sx={{
        color: "#ffffff",
      }}
    >
      <Box
        sx={{
          marginBottom: "100px",
          width: "100%",
          height: "588px",
          backgroundImage: `url(${frontImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", sm: "left", md: "left" },
        }}
      >
        <Typography
          variant="h4"
          align={isSmallScreen ? "center" : "left"}
          sx={{
            color: "white",
            fontWeight: "600",
            fontSize: { xs: "40px", sm: "40px", md: "40px" },
            lineHeight: { xs: "30px", sm: "50px", md: "55px" },
            width: "100%",
            // maxWidth: "387px",
            paddingLeft: { xs: "0px", sm: "62px", md: "87.1px" },
            textDecoration: "underline",
            textDecorationColor: "#FFFFFF",
            textUnderlineOffset: { xs: "10px", md: "15px" },
            textDecorationThickness: { xs: "3px", md: "5px" },
          }}
        >
          CONTACT US
        </Typography>
      </Box>

      <Box
        sx={{
          textAlign: "center",
          marginBottom: "40px",
          padding: { xs: "0px 0px", sm: "0px 20px", md: "0px 40px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#FFFFFF",
            fontWeight: "600",
            marginBottom: "66px",
            fontSize: { xs: "20px", sm: "30px", md: "34px" },
            textDecoration: "underline",
            textDecorationColor: "#2DDEF8",
            textUnderlineOffset: "15px",
            textDecorationThickness: "5px",
          }}
        >
          Contact us
        </Typography>

        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "100%" },
            maxWidth: "1300px",
            height: "672.63px",
            backgroundImage: `url(${contactImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "40px",
            borderRadius: "31px",
          }}
        >
          {/* Left Vector Lines */}
          {!isSmallScreen1 ? (
            <Box
              sx={{
                display: "flex",
                paddingLeft: { xs: "0%", sm: "0%", md: "25%" },
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  width: "78.21px",
                  height: "612.54px",
                  backgroundImage: `url(${Vectorline})`,
                  // backgroundSize: "cover",
                  // backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <Box
                sx={{
                  width: "78.21px",
                  height: "612.54px",
                  backgroundImage: `url(${Vectorline})`,
                  backgroundRepeat: "no-repeat",
                }}
              />
            </Box>
          ) : (
            ""
          )}

          {/* Right Side Form */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "85%", sm: "85%", md: "60%" },
              gap: 2,
              padding: { xs: "0px 20px", md: "0px 30px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: { xs: "column", sm: "column", md: "row" },
              }}
            >
              <TextField
                fullWidth
                label="Enter your name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon sx={{ color: "white" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#868484",
                    borderRadius: "10px",
                    color: "white",
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: { xs: "column", sm: "column", md: "row" },
              }}
            >
              <TextField
                fullWidth
                label="Enter your email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: "white" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#868484",
                    borderRadius: "10px",
                    color: "white",
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                label="Enter your mobile"
                variant="outlined"
                type="tel"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WifiCallingIcon sx={{ color: "white" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#868484",
                    borderRadius: "10px",
                    color: "white",
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />
            </Box>
            <TextField
              fullWidth
              label="Enter your text"
              variant="outlined"
              multiline
              rows={4}
              value={text}
              onChange={(e) => setText(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#868484",
                  borderRadius: "10px",
                  color: "white",
                  height: "114px",
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
              }}
            />
            <Button
              variant="contained"
              sx={{
                alignSelf: { xs: "center", sm: "flex-end" },
                backgroundColor: "#000000",
                color: "#2DDEF8",
                fontSize: { xs: "16px", sm: "18px", md: "20px" },
                fontWeight: "600",
                // mt: 0,
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              message="Form submitted successfully!"
              ContentProps={{
                sx: {
                  backgroundColor: "green", // Change snackbar background to green
                  color: "white", // Set text color to white for better visibility
                },
              }}
            />

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 5, mt: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "10px",
                  gap: "10px",
                }}
              >
                <InputAdornment position="start">
                  <PinDropSharpIcon
                    sx={{
                      width: "35px",
                      height: "35px",
                      color: "black",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "4px",
                      padding: "5px",
                    }}
                  />
                </InputAdornment>
                <Typography sx={{ textAlign: "left" }}>
                  Office Number 112, 1st Floor Diamond trade centre, New
                  palasia, Indore
                </Typography>
                {/* <TextField
                  fullWidth
                  placeholder="Enter address"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true, // Removes the underline
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                      paddingLeft: "8px",
                    },
                    "& .MuiInputBase-input::placeholder": { color: "white" },
                  }}
                /> */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "10px",
                  gap: "10px",
                }}
              >
                <InputAdornment position="start">
                  <WifiCallingIcon
                    sx={{
                      width: "35px",
                      height: "35px",
                      color: "black",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "4px",
                      padding: "5px",
                    }}
                  />
                </InputAdornment>
                <Typography>+91 6264385959</Typography>
                {/* <TextField
                  fullWidth
                  placeholder="Enter contact number"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                      paddingLeft: "8px",
                    },
                    "& .MuiInputBase-input::placeholder": { color: "white" },
                  }}
                /> */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "10px",
                  gap: "10px",
                }}
              >
                <InputAdornment position="start">
                  <EmailIcon
                    sx={{
                      width: "35px",
                      height: "35px",
                      color: "black",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "4px",
                      padding: "5px",
                    }}
                  />
                </InputAdornment>
                <Typography>triangleinvestment089@gmail.com</Typography>
                {/* <TextField
                  fullWidth
                  placeholder="Enter Email id"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                      paddingLeft: "8px",
                    },
                    "& .MuiInputBase-input::placeholder": { color: "white" },
                  }}
                /> */}
              </Box>
            </Box>

            {!isSmallScreen1 ? (
              <hr style={{ marginTop: "10px", width: "100%" }} />
            ) : (
              ""
            )}

            {/* Icons */}
            {!isSmallScreen1 ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  justifyContent: "end",
                }}
              >
                <IconButton>
                  <FacebookIcon
                    sx={{
                      width: "35.32px",
                      height: "35.32px",
                      color: "black",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "4px",
                      padding: "12px",
                    }}
                  />
                </IconButton>
                <IconButton>
                  <InstagramIcon
                    sx={{
                      width: "35.32px",
                      height: "35.32px",
                      color: "black",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "4px",
                      padding: "12px",
                    }}
                  />
                </IconButton>
                <IconButton>
                  <XIcon
                    sx={{
                      width: "35.32px",
                      height: "35.32px",
                      color: "black",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "4px",
                      padding: "12px",
                    }}
                  />
                </IconButton>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>

        {isSmallScreen1 ? (
          <Box
            sx={{
              display: "flex",
              gap: 3,
              justifyContent: "end",
              mt: 4,
            }}
          >
            <IconButton>
              <FacebookIcon
                sx={{
                  width: "59.32px",
                  height: "59.32px",
                  color: "black",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "4px",
                  padding: "12px",
                }}
              />
            </IconButton>
            <IconButton>
              <InstagramIcon
                sx={{
                  width: "59.32px",
                  height: "59.32px",
                  color: "black",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "4px",
                  padding: "12px",
                }}
              />
            </IconButton>
            <IconButton>
              <XIcon
                sx={{
                  width: "59.32px",
                  height: "59.32px",
                  color: "black",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "4px",
                  padding: "12px",
                }}
              />
            </IconButton>
          </Box>
        ) : (
          ""
        )}
      </Box>

      <Box
        sx={{
          marginTop: "80px",
          marginBottom: { xs: "20px", sm: "15px", md: "15px" },
        }}
      >
        {isSmallScreen ? <CardSectionMobile /> : ""}
      </Box>
    </Box>
  );
};

export default ContactPage;
