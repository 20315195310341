import React from "react";
import { Box, Button, Typography } from "@mui/material";
import backgroundImage from "../../assets/home/Group 2.png";
import graphbottom from "../../assets/home/graphbottom.png";
import handImage from "../../assets/home/handImage.png";
import { Link } from "react-router-dom";
import { mobileApplicationUrl } from "../../constant";

const HomeSectionWeb = () => {
  return (
    <Box
      sx={{
        marginBottom: {
          xs: "30px",
          sm: "50px",
          md: "50px",
        },
        width: "100%",
        height: {
          xs: "600px",
          sm: "650px",
          md: "800px",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Main Graph Image */}
      <Box
        sx={{
          width: "90%",
          height: {
            xs: "80%",
            sm: "90%",
            md: "771px",
          },
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
          top: "0",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            paddingBottom: "90px",
            width: "55%",
            paddingLeft: { xs: "0px", sm: "20px", md: "50px" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#23ECA2",
              fontWeight: "700",
              fontSize: { xs: "30px", sm: "30px", md: "40px" },
              lineHeight: { xs: "30px", sm: "40px", md: "65px" },
              width: { xs: "40%", sm: "80%", md: "91%" },
            }}
          >
            JOIN OUR COMMUNITY <br />
            MANY INVESTORS AND START YOUR JOURNEY TOWARDS LONG-TERM WEALTH
            CREATION.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", sm: "20px", md: "30px" },
              width: { xs: "40%", sm: "90%", md: "75%" },
              zIndex: "10",
            }}
          >
            <Link
              to={mobileApplicationUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#2DDEF8",
                  color: "#000000",
                  fontSize: { xs: "12px", sm: "16px", md: "20px" },
                  fontWeight: "600",
                  borderRadius: "6px",
                  p: "10px 10px",
                }}
              >
                DOWNLOAD APP
              </Button>
            </Link>
            <Button
              variant="contained"
              component={Link}
              to="/contact"
              sx={{
                backgroundColor: "#2DDEF8",
                color: "#000000",
                fontSize: { xs: "12px", sm: "16px", md: "20px" },
                fontWeight: "600",
                borderRadius: "6px",
                p: { xs: "0px 10px", sm: "0px 12px", md: "0px 25px" },
              }}
            >
              INVEST NOW
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Bottom Image */}
      <Box
        sx={{
          position: "absolute",
          bottom: {
            xs: 0,
            sm: 0,
            md: 50,
          },
          width: "100%",
          height: "282.93px",
          backgroundImage: `url(${graphbottom})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: "1",
        }}
      ></Box>

      <Box
        sx={{
          position: "absolute",
          right: {
            xs: "3%",
            sm: "3%",
            md: "2%",
            lg: "5.5%",
          },
          transform: {
            xs: "translateY(5%)",
            sm: "translateY(0%)",
            md: "translateY(-4%)",
            lg: "translateY(-1%)",
          },
          width: {
            xs: "50%",
            md: "100%",
          },
          maxWidth: "564.74px",
          height: "685.22px",
          backgroundImage: `url(${handImage})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>
    </Box>
  );
};

export default HomeSectionWeb;
