import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CardSection from "../../components/cardSection/CardSection";
import frontIMage from "../../assets/plan/frontImage.png";
import frontImageMobile from "../../assets/plan/frontImageMobile.png";
import bgImage from "../../assets/plan/bgimage.png";
import CardSectionMobile from "../../components/cardSection/CardSectionMobile";
import { Link } from "react-router-dom";
import { mobileApplicationUrl } from "../../constant";

const PlansPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const plansData = [
    { amount: "10K to 1L", interest: "38%" },
    { amount: "1L to 3L", interest: "40%" },
    { amount: "3L to 5L", interest: "43%" },
    { amount: "Above 5L", interest: "45%" },
  ];

  return (
    <Box
      component="main"
      sx={{
        color: "#ffffff",
      }}
    >
      <Box
        sx={{
          marginBottom: { xs: "35px", sm: "40px", md: "50px" },
          width: "100%",
          height: { xs: "652.72px", md: "588px" },
          backgroundImage: {
            xs: `url(${frontImageMobile})`,
            sm: `url(${frontIMage})`,
            md: `url(${frontIMage})`,
          },
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", sm: "left", md: "left" },
        }}
      >
        <Typography
          variant="h4"
          align={isSmallScreen ? "center" : "left"}
          sx={{
            color: "black",
            fontWeight: "600",
            fontSize: { xs: "40px", sm: "40px", md: "40px" },
            lineHeight: { xs: "30px", sm: "50px", md: "55px" },
            width: "100%",
            // maxWidth: "387px",
            paddingLeft: { xs: "0px", sm: "62px", md: "87.1px" },
            textDecoration: "underline",
            textDecorationColor: "black",
            textUnderlineOffset: { xs: "10px", md: "15px" },
            textDecorationThickness: { xs: "3px", md: "5px" },
          }}
        >
          OUR PLANS
        </Typography>
      </Box>

      <Typography
        variant="h4"
        sx={{
          textAlign: { xs: "center", sm: "center", md: "center" },
          color: "#FFFFFF",
          fontWeight: "600",
          fontSize: { xs: "20px", sm: "30px", md: "34px" },
          // paddingLeft: { xs: "0px", sm: "0%", md: "14%" },
          textDecoration: "underline",
          textDecorationColor: "#2DDEF8",
          textUnderlineOffset: { xs: "10px", md: "15px" },
          textDecorationThickness: { xs: "3px", md: "5px" },
        }}
      >
        Our packages
      </Typography>
      {/* 
      <Box
        sx={{
          textAlign: "center",
          mb: { xs: "40px", sm: "70px", md: "100px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "170px",
          padding: { xs: "50px 50px", md: "50px 100px" },
          flexDirection: { xs: "column", sm: "column", md: "row" },
          rowGap: "50px",
          // width: { xs: "100%", sm: "100%", md: "36%" },
        }}
      >
        <Box
          sx={{
            width: { xs: "283.64px", sm: "100%", md: "36%" },
            maxWidth: "502px",
            height: { xs: "342.6px", sm: "645px", md: "645px" },
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
          }}
        ></Box>

        <Box
          sx={{
            width: { xs: "283.64px", sm: "100%", md: "36%" },
            maxWidth: "502px",
            height: { xs: "342.6px", sm: "645px", md: "645px" },
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
          }}
        ></Box>
      </Box>  */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: { xs: "30px 20px", sm: "40px", md: "20px 60px" },
          height: { xs: "auto", sm: "auto", md: "645px" },
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "80%", md: "1066.21px" },
            maxWidth: "1066.21px",
            backgroundColor: "#65CDA7",
            borderRadius: "20px",
            padding: { xs: "20px", sm: "30px", md: "40px" },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#fff",
              textAlign: "center",
              marginBottom: { xs: "10px", sm: "20px", md: "30px" },
              fontSize: { xs: "24px", sm: "28px", md: "34px" },
              lineHeight: { xs: "32.13px", sm: "36.13px", md: "41.15px" },
              fontWeight: "600",
            }}
          >
            Our Offer's
          </Typography>

          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              "& .MuiTableCell-root": {
                borderColor: "#fff",
                color: "#fff",
                fontSize: { xs: "16px", sm: "20px", md: "24px" },
                padding: { xs: "12px", sm: "16px", md: "20px" },
                borderWidth: "2px",
                borderStyle: "solid",
              },
              "& .MuiTableRow-root": {
                "& .MuiTableCell-root": {
                  "&:first-of-type": {
                    borderLeft: "2px solid #fff",
                  },
                  "&:last-child": {
                    borderRight: "2px solid #fff",
                  },
                },
                "&:first-of-type": {
                  "& .MuiTableCell-root": {
                    borderTop: "2px solid #fff",
                  },
                },
                "&:last-child": {
                  "& .MuiTableCell-root": {
                    borderBottom: "2px solid #fff",
                  },
                },
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: "600",
                      fontSize: { xs: "26px", sm: "30px", md: "34px" },
                      lineHeight: { xs: "32px", sm: "36px", md: "41.15px" },
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: "600",
                      fontSize: { xs: "26px", sm: "30px", md: "34px" },
                      lineHeight: { xs: "32px", sm: "36px", md: "41.15px" },
                    }}
                  >
                    Rate of Interest
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plansData.map((plan, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "600",
                        fontSize: { xs: "32px", sm: "36px", md: "40px" },
                        lineHeight: { xs: "40px", sm: "44px", md: "48.41px" },
                      }}
                    >
                      {plan.amount}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "600",
                        fontSize: { xs: "32px", sm: "36px", md: "40px" },
                        lineHeight: { xs: "40px", sm: "44px", md: "48.41px" },
                      }}
                    >
                      {plan.interest}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {isSmallScreen ? (
        <Box
          sx={{
            width: { xs: "100%" },
            // height: { xs: "100%", sm: "100%", md: "750px" },
            height: "217.83px",
            mb: { xs: "80px", sm: "100px", md: "120px" },
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: "0.5",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            paddingBottom: "10px",
            zIndex: "0",
            // background: "#00000099"
          }}
        >
          <Link
            to={mobileApplicationUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#000000",
                color: "#2DDEF8",
                fontSize: "10px",
                fontWeight: "600",
                borderRadius: "6px",
                zIndex: "100000",
                p: "15px 15px",
                lineHeight: "12.1px",
                // transform: "translateY(-10%)",
              }}
            >
              DOWNLOAD APP
            </Button>
          </Link>
        </Box>
      ) : (
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "100%" },
            // height: { xs: "100%", sm: "100%", md: "750px" },
            height: "750px",
            mb: { xs: "50px", sm: "100px", md: "120px" },
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: "0.5",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            paddingBottom: "30px",
            zIndex: "0",
            // background: "#00000099"
          }}
        >
          <Link
            to={mobileApplicationUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#000000",
                color: "#2DDEF8",
                fontSize: "20px",
                fontWeight: "600",
                borderRadius: "6px",
                zIndex: "100000",
                p: "15px 25px",
                // transform: "translateY(-10%)",
              }}
            >
              DOWNLOAD APP
            </Button>
          </Link>
        </Box>
      )}

      <Box
        sx={{
          marginBottom: { xs: "20px", sm: "15px", md: "15px" },
        }}
      >
        {isSmallScreen ? <CardSectionMobile /> : <CardSection />}
      </Box>
    </Box>
  );
};

export default PlansPage;
