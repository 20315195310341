import React from "react";
import { Grid, Box, Typography, Link } from "@mui/material";
import iconImage from "../../assets/home/icon.png";

const Footer = () => {
  const centerText = {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "column" },
    alignItems: { xs: "center", sm: "flex-start", md: "flex-start" },
  };

  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { md: "space-between" },
        color: "#ffffff",
        padding: { xs: "40px 20px", sm: "50px 40px", md: "70px 70px" },
        height: { xs: "auto", md: "35vh" },
        gap: { xs: "20px", md: "0" },
      }}
    >
      {/* Left Placeholder */}
      <Box
        sx={{
          width: { xs: "100%", sm: "35%", md: "35%" },
          display: { xs: "flex", md: "block" },
          justifyContent: "center",
          paddingLeft: { xs: "0", md: "30px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "80%", sm: "245.93px" },
            height: { xs: "200px", sm: "254.84px" },
            backgroundImage: `url(${iconImage})`,
            backgroundSize: { xs: "contain", sm: "cover" },
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Box>

      {/* Footer Content */}
      <Grid
        container
        spacing={2}
        sx={{
          width: { xs: "100%", sm: "100%", md: "90%" },
          margin: 0,
          rowGap: "10px",
          display: "flex",
        }}
      >
        {/* Info Section */}
        <Grid item xs={12} sm={4} sx={centerText}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "18px", sm: "20px", md: "22px" },
              fontWeight: "bold",
              mb: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            INFORMATION
          </Typography>
          <Box
            sx={{
              listStyleType: "none",
              padding: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start", md: "flex-start" },
            }}
          >
            {[
              "Home",
              "Invest",
              "About Us",
              "Plans",
              "Contact Us",
              "Privacy Policy",
            ].map((text, index) => (
              <li
                key={index}
                style={{
                  paddingBottom: "10px",
                }}
              >
                {text === "Home" ? (
                  <Link
                    href="/"
                    color="inherit"
                    underline="hover"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    {text}
                  </Link>
                ) : text === "About Us" ? (
                  <Link
                    href="/about"
                    color="inherit"
                    underline="hover"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    {text}
                  </Link>
                ) : text === "Contact Us" ? (
                  <Link
                    href="/contact"
                    color="inherit"
                    underline="hover"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    {text}
                  </Link>
                ) : text === "Privacy Policy" ? (
                  <Link
                    href="/privacy-policy"
                    color="inherit"
                    underline="hover"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    {text}
                  </Link>
                ) : (
                  <Link
                    href={`/${text.toLowerCase().replace(" ", "")}`}
                    color="inherit"
                    underline="hover"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    {text}
                  </Link>
                )}
              </li>
            ))}
          </Box>
        </Grid>

        {/* Useful Links Section */}
        <Grid item xs={12} sm={4} sx={centerText}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "18px", sm: "20px", md: "22px" },
              fontWeight: "bold",
              mb: "10px",
            }}
          >
            USEFUL LINKS
          </Typography>
          {[
            "User Terms & Condition",
            "User Privacy Policy",
            "User Agreement",
          ].map((text, index) => (
            <Typography
              key={index}
              sx={{ fontSize: { xs: "16px", md: "18px" }, mb: "7px" }}
            >
              {text}
            </Typography>
          ))}
        </Grid>

        {/* Contact Section */}
        <Grid item xs={12} sm={4} sx={centerText}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "18px", sm: "20px", md: "22px" },
              fontWeight: "bold",
              mb: "10px",
            }}
          >
            CONTACT US
          </Typography>

          <Typography sx={{ fontSize: { xs: "16px", md: "18px" }, mb: "7px" }}>
            +91 6264385959
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              mb: "7px",
              wordBreak: "break-word", // Ensures word breaks if necessary
              overflowWrap: "break-word", // Handles long words breaking across lines
            }}
          >
            triangleinvestment089@gmail.com
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              mb: "7px",
              textAlign: { xs: "center", sm: "left", md: "left" },
            }}
          >
            Office Number 112, 1st Floor Diamond trade centre, New palasia,
            Indore
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
