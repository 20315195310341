import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import iconImage from "../../assets/home/icon.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import { styled, useTheme } from "@mui/material/styles";
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PlansIcon from '@mui/icons-material/Description';
import PolicyIcon from '@mui/icons-material/Policy';

const Header = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const isActive = (path) => location.pathname === path;

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const buttonStyle = (path) => ({
    gap: "20px",
    fontSize: "16px",
    fontWeight: "600",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: isActive(path)
        ? "translateX(-50%) scaleX(1)"
        : "translateX(-50%) scaleX(0)",
      width: "100%",
      height: "2px",
      backgroundColor: "#fff",
      transition: "transform 0.3s ease-in-out",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      transform: "translateY(-2px)",
      transition: "all 0.3s ease",
      "&::after": {
        transform: "translateX(-50%) scaleX(1)",
      },
    },
    ...(isActive(path) && {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    }),
  });

  const drawerItemStyle = (path) => ({
    position: "relative",
    transition: "all 0.3s ease",
    color: "#3a3e3d",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "2px",
      backgroundColor: "#23ECA2",
      transform: isActive(path) ? "scaleX(1)" : "scaleX(0)",
      transition: "transform 0.3s ease-in-out",
    },
    "&:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
      transform: "translateX(8px)",
      "&::after": {
        transform: "scaleX(1)",
      },
    },
    ...(isActive(path) && {
      backgroundColor: " rgb(17 255 5 / 12%)",
      // rgb(17 255 5 / 12%)
      "& .MuiListItemText-primary": {
        fontWeight: 600,
        color: "#23ECA2",
      },
      "& .MuiListItemIcon-root": {
        color: "#23ECA2",
      },
    }),
  });

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: scrolled
            ? "rgba(255, 255, 255, 0.2)"
            : "transparent",
          backdropFilter: scrolled ? "blur(10px)" : "none",
          boxShadow: scrolled ? "0 4px 30px rgba(0, 0, 0, 0.1)" : "none",
          padding: { xs: "0px", md: "10px 15px" },
          transition: "all 0.3s ease-in-out",
          "& .MuiButton-root": {
            color: scrolled ? "#000" : "#fff",
          },
        }}
      >
        <Toolbar>
          {/* <Typography
            variant="h6"
            sx={{ flexGrow: 1, fontSize: "24px", fontWeight: "600" }}
          >
            LOGO
          </Typography> */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "block", md: "block" },
              justifyContent: "left",
              paddingTopTop: "20px",
            }}
          >
            <Box
              sx={{
                width: { xs: "27.05px", sm: "30.05px", md: "40px" },
                height: { xs: "28.03px", sm: "30.03px", md: "40px" },
                backgroundImage: `url(${iconImage})`,
                backgroundSize: { xs: "contain", sm: "contain" },
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          </Box>

          {/* For larger screens */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: { xs: "none", md: "space-between" },
              width: { sm: "50%", md: "75%", lg: "55%" },
              // maxWidth: "75%",
              // gap:'45px'
            }}
          >
            <Button
              color="inherit"
              component={Link}
              to="/"
              sx={buttonStyle("/")}
            >
              HOME
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/invest"
              sx={buttonStyle("/invest")}
            >
              INVEST
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/about"
              sx={buttonStyle("/about")}
            >
              ABOUT US
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/plans"
              sx={buttonStyle("/plans")}
            >
              PLANS
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/contact"
              sx={buttonStyle("/contact")}
            >
              CONTACT US
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/privacy-policy"
              sx={buttonStyle("/privacy-policy")}
            >
              PRIVACY POLICY
            </Button>
          </Box>

          {/* For smaller screens */}
          <IconButton
            color="inherit"
            edge="end"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile view */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <DrawerHeader>
              <IconButton onClick={toggleDrawer(false)}>
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />

            <ListItem button component={Link} to="/" sx={drawerItemStyle("/")}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="HOME" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/invest"
              sx={drawerItemStyle("/invest")}
            >
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="INVEST" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/about"
              sx={drawerItemStyle("/about")}
            >
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="ABOUT US" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/plans"
              sx={drawerItemStyle("/plans")}
            >
              <ListItemIcon>
                <PlansIcon />
              </ListItemIcon>
              <ListItemText primary="PLANS" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/contact"
              sx={drawerItemStyle("/contact")}
            >
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary="CONTACT" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/privacy-policy"
              sx={drawerItemStyle("/privacy-policy")}
            >
              <ListItemIcon>
                <PolicyIcon />
              </ListItemIcon>
              <ListItemText primary="PRIVACY POLICY" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
