import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import GroupInfo from "../../assets/home/GroupInfo.png";

// Custom styled components for buttons with gradient background
const GradientButton = styled(Button)(({ theme }) => ({
  background: "radial-gradient(50% 50% at 50% 50%, #8EFED5 0%, #1ECB8C 100%)",
  color: "#101011",
  borderRadius: "50px",
  padding: "10px 20px",
  width: "100%",
  maxWidth: "450px",
  height: "70px",
  justifyContent: "flex-start",
  display: "flex",
  alignItems: "center",
  transition: "background 0.3s ease-in-out",
  // overflow: "hidden",
  "&:hover": {
    background: "linear-gradient(90deg, #018354 0%, #28FFC8 100%)",
  },
  [theme.breakpoints.down("sm")]: {
    height: "50px",
    maxWidth: "100%",
    padding: "8px 15px",
  },
}));

// Circle with number inside
const StepCircle = styled(Box)(({ theme }) => ({
  width: "65px",
  height: "66px",
  backgroundColor: "#7CE5C0",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#018354",
  fontSize: "50px",
  fontWeight: "700",
  marginLeft: "-65px", // Half of the width of the circle to overlap the button
  // marginRight: "15px", // Gap between circle and text
  position: "relative",
  zIndex: 2,
  [theme.breakpoints.down("sm")]: {
    width: "40px",
    height: "40px",
    fontSize: "18px",
    marginLeft: "-45px",
    // marginRight: "10px",
  },
}));

// Circle background for dark shadow effect
const StepCircle1 = styled(Box)(({ theme }) => ({
  width: "65px",
  height: "66px",
  backgroundColor: "#101011",
  borderRadius: "50%",
  // marginLeft: "-40px", // Half of the width of the circle to overlap the button
  // marginRight: "-20px", // Gap between circle and text
  position: "absolute",
  left: "-20px",
  zIndex: 1,

  [theme.breakpoints.down("sm")]: {
    width: "40px",
    height: "40px",
    // marginLeft: "-15px",
    // marginRight: "10px",
    left: "-12px",
  },
}));

const StepText = styled("span")(({ theme }) => ({
  // fontSize: "26px",
  fontWeight: "600",
  color: "#000000",
  marginLeft: "40px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    marginLeft: "40px",
  },
}));

// Vertical line between circles
const StepConnector = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "66px",
  left: "-11px",
  width: "2px",
  height: "40px",
  backgroundColor: "#7CE5C0",
  zIndex: 1,
  [theme.breakpoints.down("sm")]: {
    top: "40px",
    left: "-10px",
    height: "50px",
  },
}));

const infoSection = () => {
  return (
    <Box
      sx={{ padding: { xs: "0px 20px", md: "0px 100px" } }}
      marginTop={"100px"}
    >
      <Typography
        variant="h5"
        align="center"
        fontWeight={"600"}
        fontSize={"26px"}
        gutterBottom
      >
        OUR 10 MIN - PROMISE
      </Typography>
      <Typography
        variant="body2"
        align="center"
        fontWeight={"600"}
        fontSize={"14px"}
        gutterBottom
        sx={{
          textDecoration: "underline",
          textDecorationColor: "#2DDEF8",
          textUnderlineOffset: "15px",
          textDecorationThickness: "5px",
        }}
      >
        GET YOUR ACCOUNT SETUP SUPER FAST WITH US
      </Typography>

      <Grid
        container
        spacing={10}
        alignItems="center"
        justifyContent={"space-between"}
        // paddingTop={10}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 0, md: 8 },
          // marginTop:{ xs: "10px", md: "0px" },
        }}
      >
        {/* Left Side: Image */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              // paddingLeft: "10px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              src={GroupInfo}
              alt="Illustration"
              sx={{
                width: "100%",
                maxWidth: "494.19px",
                height: "440.55px",
              }}
            />
          </Box>

          {/* White box with text inside */}
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "5px",
              width: "100%",
              maxWidth: "460.96px",
              height: "100%",
              borderRadius: "13px",
              marginLeft: "25px",
              marginTop: "30px",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              align="left"
              sx={{
                display: "block",
                color: "#101011",
                maxWidth: "436.86px",
                fontSize: "20px",
                fontWeight: "600",
                // paddingLeft: "10px",
                lineHeight: "24.2px",
              }}
            >
              *Have your PAN, Bank and Aadhaar information handy
            </Typography>
          </Box>

          {/* Text below white box */}
          <Typography
            variant="body2"
            align="left"
            sx={{
              mt: 1,
              paddingLeft: "40px",
              width: "100%",
              maxWidth: "436.86px",
              // height: "31.63px",
              height: "100%",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "19.36px",
            }}
          >
            In some cases, the account activation may take up to 72 hours as we
            are dependent on KRAs to process your KYC.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              position: "relative",
              paddingTop: "20px",
            }}
          >
            {/* Button with steps */}
            {[
              { step: 1, label: "PAN Details" },
              { step: 2, label: "KYC Details" },
              { step: 3, label: "Add your nominee" },
              { step: 4, label: "Link your bank" },
              { step: 5, label: "Address" },
            ].map((item, index) => (
              <Box key={index} sx={{ position: "relative" }}>
                <GradientButton>
                  <StepCircle>{item.step}</StepCircle>
                  <StepCircle1 />
                  <StepText
                    sx={{
                      fontSize: { xs: "15px", sm: "20px", md: "26px" },
                      fontWeight: "600",
                    }}
                  >
                    {item.label}
                  </StepText>
                </GradientButton>
                {index < 4 && <StepConnector />}{" "}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default infoSection;
