import React from "react";
import {
  Box,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const PrivacyPolicyPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const headingStyle = {
    textAlign: "center",
    fontWeight: "bold",
  };

  const paragraphStyle = {
    textAlign: "center",
  };

  return (
    <Box
      component="main"
      sx={{
        color: "#ffffff",
        padding: isSmallScreen ? 2 : 4,
        lineHeight: 1.6,
        marginTop: 10,
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom sx={headingStyle}>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={paragraphStyle}>
          Last updated: October 24, 2024
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the Privacy Policy Generator.
        </Typography>

        {/* Interpretation and Definitions */}
        <Typography
          variant="h5"
          gutterBottom
          sx={{ marginTop: 3, ...headingStyle }}
        >
          Interpretation and Definitions
        </Typography>
        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Interpretation
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </Typography>

        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Definitions
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          For the purposes of this Privacy Policy:
        </Typography>
        <Typography component="ul" sx={{ paddingLeft: 2 }}>
          <li>
            <strong>Account:</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </li>
          <li>
            <strong>Affiliate:</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest or other securities entitled to vote for election of
            directors or other managing authority.
          </li>
          <li>
            <strong>Application:</strong> refers to Triangle Investments, the
            software program provided by the Company.
          </li>
          <li>
            <strong>Company:</strong> (referred to as either "the Company",
            "We", "Us" or "Our" in this Agreement) refers to Triangle
            Investments, Office Number 112, 1st Floor Diamond Trade Centre, New
            Palasia, Indore.
          </li>
          <li>
            <strong>Country:</strong> refers to Madhya Pradesh, India.
          </li>
          <li>
            <strong>Device:</strong> means any device that can access the
            Service such as a computer, a cellphone or a digital tablet.
          </li>
          <li>
            <strong>Personal Data:</strong> is any information that relates to
            an identified or identifiable individual.
          </li>
          <li>
            <strong>Service:</strong> refers to the Application.
          </li>
          <li>
            <strong>Service Provider:</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </li>
          <li>
            <strong>Usage Data:</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </li>
          <li>
            <strong>You:</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
        </Typography>

        {/* Additional Sections */}
        <Typography
          variant="h5"
          gutterBottom
          sx={{ marginTop: 3, ...headingStyle }}
        >
          Collecting and Using Your Personal Data
        </Typography>
        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Types of Data Collected
        </Typography>
        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Personal Data
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </Typography>
        <Typography component="ul" sx={{ paddingLeft: 2 }}>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Usage Data</li>
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 3, ...headingStyle }}
        >
          Usage Data
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          Usage Data is collected automatically when using the Service.
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </Typography>

        {/* ---------------- */}
        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Use of Your Personal Data
        </Typography>
        <Typography paragraph>
          The Company may use Personal Data for the following purposes:
        </Typography>
        <Typography component="ul" sx={{ paddingLeft: 2 }}>
          <li>
            <strong>To provide and maintain our Service:</strong>including to
            monitor the usage of our Service.
          </li>
          <li>
            <strong>To manage Your Account:</strong> to manage Your registration
            as a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
          </li>
          <li>
            <strong>To contact You:</strong> To contact You by email, telephone
            calls, SMS, for new informative communications related to the
            functionalities, services, implementation.
          </li>
          <li>
            <strong>To manage Your requests:</strong> To attend and manage Your
            requests to Us.
          </li>
          <li>
            <strong>For other purposes:</strong> We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service and your experience.
          </li>
        </Typography>

        {/* Add other sections here following a similar structure */}

        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 3, ...headingStyle }}
        >
          Retention of Your Personal Data
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </Typography>

        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Transfer of Your Personal Data
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          Your information, including Personal Data, is processed at the
          Company's operating offices and we don't share your personal data.
        </Typography>

        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Delete Your Personal Data
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          sx={{ marginTop: 3, ...headingStyle }}
        >
          Disclosure of Your Personal Data
        </Typography>
        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Business Transactions
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </Typography>

        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Law enforcement
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 2, ...headingStyle }}
        >
          Other legal requirements
        </Typography>
        <Typography paragraph>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </Typography>
        <Typography component="ul" sx={{ paddingLeft: 2 }}>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 2, ...headingStyle }}
        >
          Security of Your Personal Data
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </Typography>

        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Children's Privacy
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          Our Service does not address anyone under the age of 18. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 18. If We become aware that We have collected
          Personal Data from anyone under the age of 18 without verification of
          parental consent, We take steps to remove that information from Our
          servers.
        </Typography>

        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Changes to this Privacy Policy
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          sx={{ marginTop: 3, ...headingStyle }}
        >
          Developer Information
        </Typography>
        <Typography variant="h6" gutterBottom sx={headingStyle}>
          Contact Us
        </Typography>
        <Typography paragraph sx={paragraphStyle}>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </Typography>
        <Typography
          component="ul"
          sx={{ paddingLeft: 2, listStyleType: "none", ...paragraphStyle }}
        >
          <li>
            <strong>Company Name:</strong> Triangle Investments
          </li>
          <li>
            <strong>Address:</strong> Office Number 112, 1st Floor Diamond Trade
            Centre, New Palasia, Indore
          </li>
          <li>
            <strong>Contact Email:</strong> Triangleinvestment089@gmail.com
          </li>
          <li>
            <strong>Phone Number:</strong> 6264385959
          </li>
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicyPage;
